import React from 'react';

class ContactForm extends React.Component {
	render() {
		return (
			<form
				name="contact-form"
				method="post"
				action={this.props.redirect}
				data-netlify="true"
				data-netlify-honeypot="bot-field"
			>
				<input type="hidden" name="bot-field" />
				<input type="hidden" name="form-name" value="contact" />
				<div className="form-group">
					<div className="form-row">
						<div className="col">
							<label>{this.props.firstNameTitle}</label>
							<input
								type="text"
								className="form-control"
								id="firstName"
								name="firstName"
								placeholder={this.props.firstNamePlaceholder}
							/>
						</div>
						<div className="col">
							<label>{this.props.emailTitle}</label>
							<input
								type="email"
								className="form-control"
								id="email"
								name="email"
								placeholder={this.props.emailPlaceholder}
							/>
						</div>
					</div>
				</div>
				<div className="form-group">
					<label>{this.props.messageTitle}</label>
					<textarea
						rows="5"
						type="text"
						className="form-control"
						id="message"
						name="message"
						placeholder={this.props.messagePlaceholder}
					/>
				</div>
				<input type="hidden" name="bot-field" />
				<button type="submit" className="btn btn-primary">
					{this.props.cta}
				</button>
			</form>
		);
	}
}

export default ContactForm;
