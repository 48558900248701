import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import ContactForm from '../../components/ContactForm';

const Contact = ({ data }) => (
	<Layout bodyClass="page-contact">
		<SEO title={data.prismicContact.data.title.text} />
		<Helmet>
			<link
				rel="canonical"
				href={`${data.site.siteMetadata.siteUrl}en/contact/`}
			/>
			<link
				rel="alternate"
				href={`${data.site.siteMetadata.siteUrl}en/contact/`}
				hreflang="en"
			/>
			<link
				rel="alternate"
				href={`${data.site.siteMetadata.siteUrl}en/contact/`}
				hreflang="sv-se"
			/>
		</Helmet>

		<div className="container pt-4 pt-md-10 mb-10">
			<div className="row justify-content-start">
				<div className="col-12 col-md-8">
					<div className="service service-single">
						<h1 className="title">{data.prismicContact.data.title.text}</h1>
						<div>
							<strong>{data.prismicContact.data.email_text.text}:</strong>
							<br />
							<a>{data.site.siteMetadata.contact.email}</a>
						</div>
						<div>
							<strong>{data.prismicContact.data.phone_text.text}:</strong>
							<br />
							<a href={`tel:${data.site.siteMetadata.contact.phone}`}>
								{data.site.siteMetadata.contact.phone}
							</a>
						</div>
					</div>
				</div>
				<div className="container pt-2">
					<ContactForm
						emailTitle={data.prismicContact.data.email_title.text}
						emailPlaceholder={data.prismicContact.data.email_placeholder.text}
						firstNameTitle={data.prismicContact.data.name_title.text}
						firstNamePlaceholder={
							data.prismicContact.data.name_placeholder.text
						}
						messageTitle={data.prismicContact.data.message_title.text}
						messagePlaceholder={
							data.prismicContact.data.message_placeholder.text
						}
						cta={data.prismicContact.data.button_text.text}
						redirect="/en/thanks/"
					/>
				</div>
			</div>
		</div>
	</Layout>
);

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
				siteUrl
				description
				contact {
					email
					phone
				}
			}
		}
		prismicContact(lang: { eq: "en-gb" }) {
			data {
				title {
					text
				}
				email_text {
					text
				}
				phone_text {
					text
				}
				name_title {
					text
				}
				name_placeholder {
					text
				}
				email_title {
					text
				}
				email_placeholder {
					text
				}
				message_title {
					text
				}
				message_placeholder {
					text
				}
				button_text {
					text
				}
			}
		}
	}
`;

export default Contact;
